import { connect } from 'react-redux';

import { MessageListener } from 'componentlibrary';
import { authorize } from '../../reducers/auth';
import { setIframeHistoryUrl } from '../../reducers/iframeHistory';
import config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  acceptedOrigin: config.reactApp.IFRAME_ORIGIN_URL,
  showAuthConfirm: state.auth.showAuthConfirm,
  currentScreen: state.iframeHistory.currentScreen,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  onRequestAuthenAction() {
    return authorize(dispatch);
  },
  onIframeLoaded(payload, currentScreen) {
    return setIframeHistoryUrl(dispatch, { name: currentScreen, url: payload });
  },
  onRequestOpenUrl(payload) {
    const anchor = document.createElement('a');
    anchor.setAttribute('href', payload.url);
    anchor.click();
  }
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onIframeLoaded(payload) {
    if (stateProps.currentScreen) {
      dispatchProps.onIframeLoaded(payload, stateProps.currentScreen);
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(MessageListener);
