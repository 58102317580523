import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { LoadingIframe, getParsedIrameUrl } from 'componentlibrary';
import Can from '../Can';
import Config from '../../Config';
import { TEAM_TARGET_DASHBOARD } from '../../constants/screens';
import { CASL_ACTION } from '../../constants/caslAction';

export default function TeamTargetDashboardComponent({
  currentUrl, currentLanguage, onUnMount, onMount
}) {
  const parsedIframeUrl = useMemo(() => getParsedIrameUrl(currentUrl, currentLanguage), [currentLanguage]);

  useEffect(() => {
    onMount(getParsedIrameUrl(Config.reactApp.TEAM_TARGET_DASHBOARD_URL, currentLanguage));

    return () => {
      onUnMount();
    };
  }, []);

  return (
    <Can I={CASL_ACTION.view} a={TEAM_TARGET_DASHBOARD}>
      <LoadingIframe src={parsedIframeUrl} />
    </Can>
  );
}

TeamTargetDashboardComponent.propTypes = {
  currentLanguage: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  onMount: PropTypes.func,
  onUnMount: PropTypes.func
};

TeamTargetDashboardComponent.defaultProps = {
  onUnMount: () => {},
  onMount: () => {}
};
