import { connect } from 'react-redux';
import MaintainDefaultTargets from '../../Components/MaintainDefaultTargets';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { MAINTAIN_DEFAULT_TARGETS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[MAINTAIN_DEFAULT_TARGETS],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(MaintainDefaultTargets, {
  name: MAINTAIN_DEFAULT_TARGETS,
  defaultUrl: Config.reactApp.MAINTAIN_DEFAULT_TARGETS_URL
}));
