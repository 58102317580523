import { connect } from 'react-redux';
import SetIndividualTargets from '../../Components/SetIndividualTargets';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { SET_INDIVIDUAL_TARGETS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[SET_INDIVIDUAL_TARGETS],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(SetIndividualTargets, {
  name: SET_INDIVIDUAL_TARGETS,
  defaultUrl: Config.reactApp.SET_INDIVIDUAL_TARGETS_URL
}));
