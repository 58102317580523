import Config from '../Config';
import * as screens from '../constants/screens';

const IFRAME_HISTORY_CHANGE_URL = 'IFRAME_HISTORY_CHANGE_URL';
const IFRAME_HISTORY_CURRENT_SCREEN = 'IFRAME_HISTORY_CURRENT_SCREEN';

export function iframeHistoryDefaultState() {
  return {
    currentScreen: null,
    [screens.HOME_PAGE]: Config.reactApp.CRM_HOMEPAGE_URL,
    [screens.MAINTAIN_DEFAULT_TARGETS]: Config.reactApp.MAINTAIN_DEFAULT_TARGETS_URL,
    [screens.SET_INDIVIDUAL_TARGETS]: Config.reactApp.SET_INDIVIDUAL_TARGETS_URL,
    [screens.SET_BULK_TARGETS]: Config.reactApp.SET_BULK_TARGETS_URL,
    [screens.TEAM_TARGET_DASHBOARD]: Config.reactApp.TEAM_TARGET_DASHBOARD_URL,
    [screens.SITE_TARGET_DASHBOARD]: Config.reactApp.SITE_TARGET_DASHBOARD_URL,
  };
}

export function setCurrentScreen(dispatch, payload) {
  dispatch({
    type: IFRAME_HISTORY_CURRENT_SCREEN,
    payload
  });
}

export function setIframeHistoryUrl(dispatch, { name, url }) {
  dispatch({
    type: IFRAME_HISTORY_CHANGE_URL,
    payload: { name, url }
  });
}

export default (state = iframeHistoryDefaultState(), action) => {
  const { payload, type } = action;
  switch (type) {
    case IFRAME_HISTORY_CURRENT_SCREEN:
      return {
        ...state,
        currentScreen: payload
      };

    case IFRAME_HISTORY_CHANGE_URL:
      return {
        ...state,
        [payload.name]: payload.url
      };

    default:
      return state;
  }
};
