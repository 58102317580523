import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

import { CASL_ACTION } from './constants/caslAction';
import { CASL_SUBJECT, toKebabCase } from './constants/caslSubject';
import Home from './Containers/Home';
import MaintainDefaultTargets from './Containers/MaintainDefaultTargets';
import SetIndividualTargets from './Containers/SetIndividualTargets';
import SetBulkTargets from './Containers/SetBulkTargets';
import SiteTargetDashboard from './Containers/SiteTargetDashboard';
import TeamTargetDashboard from './Containers/TeamTargetDashboard';

/*
  For documentation on deeply nested routes refer here:
  https://github.com/reacttraining/react-router/tree/master/packages/react-router-config
*/
const Root = ({ route }) => (
  <>
    {/* child routes won't render without this */}
    {renderRoutes(route.routes)}
  </>
);

const IDHomepage = () => {
  const getDomainWithoutFirstSubdomain = () => {
    const domain = window.location.hostname;
    const domainParts = domain.split('.');
    return domainParts.slice(1).join('.');
  };

  window.location.href = `https://id.${getDomainWithoutFirstSubdomain()}`;
};

export const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        component: Home
      },
      {
        path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.MaintainDefaultTargets)}`,
        exact: true,
        component: MaintainDefaultTargets
      },
      {
        path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SetIndividualTargets)}`,
        exact: true,
        component: SetIndividualTargets
      },
      {
        path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SetBulkTargets)}`,
        exact: true,
        component: SetBulkTargets
      },
      {
        path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SiteTargetDashboard)}`,
        exact: true,
        component: SiteTargetDashboard
      },
      {
        path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.TeamTargetDashboard)}`,
        exact: true,
        component: TeamTargetDashboard
      },
      {
        path: '/id-homepage',
        exact: true,
        component: IDHomepage,
      },
    ]
  }
];

Root.propTypes = {
  route: PropTypes.object.isRequired
};
