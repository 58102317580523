import { combineReducers } from 'redux';
import appConfig from './appConfig';
import auth from './auth';
import microFrontend from './microFrontend';
import iframeHistory from './iframeHistory';

const appReducer = combineReducers({
  appConfig,
  microFrontend,
  auth,
  iframeHistory
});

export default (state, action) => appReducer(state, action);
