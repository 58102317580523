import { connect } from 'react-redux';
import SiteTargetDashboard from '../../Components/SiteTargetDashboard';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { SITE_TARGET_DASHBOARD } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[SITE_TARGET_DASHBOARD],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(SiteTargetDashboard, {
  name: SITE_TARGET_DASHBOARD,
  defaultUrl: Config.reactApp.SITE_TARGET_DASHBOARD_URL
}));
