export const CASL_SUBJECT = {
  LeadershipParticipationMenu: 'LeadershipParticipationMenu',
  MaintainDefaultTargets: 'MaintainDefaultTargets',
  SetIndividualTargets: 'SetIndividualTargets',
  SetBulkTargets: 'SetBulkTargets',
  SiteTargetDashboard: 'SiteTargetDashboard',
  TeamTargetDashboard: 'TeamTargetDashboard',
  HomePage: 'HomePage'
};

export function toKebabCase(str) {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}
