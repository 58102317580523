import { CASL_ACTION } from '../../constants/caslAction';
import { CASL_SUBJECT, toKebabCase } from '../../constants/caslSubject';
import * as screens from '../../constants/screens';

/**
 *
 * @param {*} ability function get from AbilityContext
 * @param {*} t translation function
 */
export const buildLeadershipParticipationMenuItems = (ability, t) => {
  const menuItems = [];

  if (!ability) {
    return [];
  }

  if (ability.can(CASL_ACTION.view, screens.MAINTAIN_DEFAULT_TARGETS)) {
    menuItems.push({
      icon: undefined,
      title: t(`sideMenu:${CASL_SUBJECT.MaintainDefaultTargets}`),
      path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.MaintainDefaultTargets)}`
    });
  }

  if (ability.can(CASL_ACTION.view, screens.SET_INDIVIDUAL_TARGETS)) {
    menuItems.push({
      icon: undefined,
      title: t(`sideMenu:${CASL_SUBJECT.SetIndividualTargets}`),
      path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SetIndividualTargets)}`
    });
  }

  if (ability.can(CASL_ACTION.view, screens.SET_BULK_TARGETS)) {
    menuItems.push({
      icon: undefined,
      title: t(`sideMenu:${CASL_SUBJECT.SetBulkTargets}`),
      path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SetBulkTargets)}`
    });
  }

  if (ability.can(CASL_ACTION.view, screens.TEAM_TARGET_DASHBOARD)) {
    menuItems.push({
      icon: undefined,
      title: t(`sideMenu:${CASL_SUBJECT.TeamTargetDashboard}`),
      path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.TeamTargetDashboard)}`
    });
  }

  if (ability.can(CASL_ACTION.view, screens.SITE_TARGET_DASHBOARD)) {
    menuItems.push({
      icon: undefined,
      title: t(`sideMenu:${CASL_SUBJECT.SiteTargetDashboard}`),
      path: `/${CASL_ACTION.view}-${toKebabCase(CASL_SUBJECT.SiteTargetDashboard)}`
    });
  }

  return menuItems;
};
