import { connect } from 'react-redux';
import { setIframeHistoryUrl, setCurrentScreen } from '../../reducers/iframeHistory';

export default function index(WrappedComponent, {
  name, defaultUrl
}) {
  const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
  });

  const mapDispatchToProps = (dispatch) => ({
    onMount(initialUrl) {
      setCurrentScreen(dispatch, name);
      setIframeHistoryUrl(dispatch, { name, url: initialUrl });
    },
    onUnMount() {
      setCurrentScreen(dispatch, null);
      setIframeHistoryUrl(dispatch, { name, url: defaultUrl });
    }
  });

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent);
}
