import { connect } from 'react-redux';
import Home from '../../Components/Home';
import { mapToIframeLanguage } from '../../translations/i18n';
import { setIframeHistoryUrl, setCurrentScreen } from '../../reducers/iframeHistory';
import { HOME_PAGE } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[HOME_PAGE],
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  onMount(initialUrl) {
    setCurrentScreen(dispatch, HOME_PAGE);
    setIframeHistoryUrl(dispatch, { name: HOME_PAGE, url: initialUrl });
  },
  onUnMount() {
    setCurrentScreen(dispatch, null);
    setIframeHistoryUrl(dispatch, { name: HOME_PAGE, url: Config.reactApp.CRM_HOMEPAGE_URL });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
