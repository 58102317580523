import { connect } from 'react-redux';
import SetBulkTargets from '../../Components/SetBulkTargets';
import withIframeHistory from '../withIframeHistory';
import { mapToIframeLanguage } from '../../translations/i18n';
import { SET_BULK_TARGETS } from '../../constants/screens';
import Config from '../../Config';

const mapStateToProps = (state, ownProps) => ({
  currentLanguage: mapToIframeLanguage(state.auth.currentLanguage),
  currentUrl: state.iframeHistory[SET_BULK_TARGETS],
  ...ownProps,
});

export default connect(mapStateToProps, {})(withIframeHistory(SetBulkTargets, {
  name: SET_BULK_TARGETS,
  defaultUrl: Config.reactApp.SET_BULK_TARGETS_URL
}));
